export const FirebaseConfig = {
	"projectId": "ari-transporte-3",
	"appId": "1:141572991027:web:5c44aecec200a84cf39b46",
	"databaseURL": "https://ari-transporte-3-default-rtdb.firebaseio.com",
	"storageBucket": "ari-transporte-3.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAXz0Dqz5FqAncOJpbRLF40ak7kjgCvejw",
	"authDomain": "ari-transporte-3.firebaseapp.com",
	"messagingSenderId": "141572991027",
	"measurementId": "G-9L3JD2T2FH"
};